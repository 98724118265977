var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "title-bar",
        { attrs: { title: "Benutzer" } },
        [
          _c(
            "router-link",
            {
              staticClass: "button is-primary",
              attrs: { slot: "right", to: "/user/new" },
              slot: "right"
            },
            [
              _c("b-icon", {
                staticClass: "mr-1",
                attrs: {
                  icon: _vm.$func.getIcon("new").icon,
                  pack: _vm.$func.getIcon("new").pack
                }
              }),
              _vm._v(" Neuen Benutzer anlegen ")
            ],
            1
          )
        ],
        1
      ),
      _c(
        "section",
        { staticClass: "section is-main-section" },
        [
          _c(
            "card-component",
            { staticClass: "has-table has-mobile-sort-spaced" },
            [
              _c(
                "div",
                [
                  _c("trash-box", {
                    attrs: {
                      "is-active": _vm.isModalActive,
                      "trash-object-name": _vm.trashObjectName
                    },
                    on: { confirm: _vm.trashConfirm, cancel: _vm.trashCancel }
                  }),
                  _c(
                    "b-table",
                    {
                      attrs: {
                        "checked-rows": _vm.checkedRows,
                        checkable: _vm.checkable,
                        loading: _vm.isLoading,
                        paginated: _vm.paginated,
                        "per-page": _vm.perPage,
                        striped: true,
                        hoverable: true,
                        "sort-icon": _vm.$func.getIcon("sort-up").icon,
                        "sort-icon-pack": _vm.$func.getIcon("sort-up").pack,
                        "default-sort": "name",
                        data: _vm.users
                      },
                      on: {
                        "update:checkedRows": function($event) {
                          _vm.checkedRows = $event
                        },
                        "update:checked-rows": function($event) {
                          _vm.checkedRows = $event
                        }
                      }
                    },
                    [
                      _c("b-table-column", {
                        attrs: {
                          label: "Name",
                          field: "name",
                          sortable: "",
                          searchable: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(props) {
                              return [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "user.edit",
                                        params: { id: props.row.id }
                                      }
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(props.row.name) + " ")]
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("b-table-column", {
                        attrs: {
                          label: "E-Mail",
                          field: "email",
                          sortable: "",
                          searchable: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(props) {
                              return [
                                _vm._v(" " + _vm._s(props.row.email) + " ")
                              ]
                            }
                          }
                        ])
                      }),
                      _c("b-table-column", {
                        attrs: {
                          "custom-key": "actions",
                          "cell-class": "is-actions-cell"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(props) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "buttons is-right" },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass:
                                          "button is-small is-primary",
                                        attrs: {
                                          to: {
                                            name: "user.edit",
                                            params: { id: props.row.id }
                                          }
                                        }
                                      },
                                      [
                                        _c("b-icon", {
                                          attrs: {
                                            icon: _vm.$func.getIcon("edit")
                                              .icon,
                                            pack: _vm.$func.getIcon("edit")
                                              .pack,
                                            size: "is-small"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "button is-small is-danger",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.trashModal(props.row)
                                          }
                                        }
                                      },
                                      [
                                        _c("b-icon", {
                                          attrs: {
                                            icon: _vm.$func.getIcon("delete")
                                              .icon,
                                            pack: _vm.$func.getIcon("delete")
                                              .pack,
                                            size: "is-small"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c(
                        "section",
                        {
                          staticClass: "section",
                          attrs: { slot: "empty" },
                          slot: "empty"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "content has-text-grey has-text-centered"
                            },
                            [
                              _vm.isLoading
                                ? [_c("p", [_vm._v("Hole Daten...")])]
                                : [
                                    _c(
                                      "p",
                                      [
                                        _c("b-icon", {
                                          attrs: {
                                            icon: _vm.$func.getIcon("sad").icon,
                                            pack: _vm.$func.getIcon("sad").pack,
                                            size: "is-large"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c("p", [_vm._v("Nichts gefunden…")])
                                  ]
                            ],
                            2
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }