var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { active: _vm.isModalActive, "has-modal-card": "" },
      on: {
        "update:active": function($event) {
          _vm.isModalActive = $event
        }
      }
    },
    [
      _c("div", { staticClass: "modal-card" }, [
        _c("header", { staticClass: "modal-card-head" }, [
          _c("p", { staticClass: "modal-card-title" }, [
            _vm._v("Aktion bestätigen")
          ])
        ]),
        _c("section", { staticClass: "modal-card-body" }, [
          _c("p", [
            _c("b", [_vm._v(_vm._s(_vm.trashObjectName))]),
            _vm._v(" wird permanent gelöscht. ")
          ]),
          _c("p", [_vm._v("Aktion kann nicht rückgängig gemacht werden.")])
        ]),
        _c("footer", { staticClass: "modal-card-foot" }, [
          _c(
            "button",
            {
              staticClass: "button",
              attrs: { type: "button" },
              on: { click: _vm.cancel }
            },
            [_vm._v("Abbrechen")]
          ),
          _c(
            "button",
            { staticClass: "button is-danger", on: { click: _vm.confirm } },
            [_vm._v("Löschen")]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }