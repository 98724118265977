<template>
  <b-modal :active.sync="isModalActive" has-modal-card>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Aktion bestätigen</p>
      </header>
      <section class="modal-card-body">
        <p>
          <b>{{ trashObjectName }}</b> wird permanent gelöscht.
        </p>
        <p>Aktion kann nicht rückgängig gemacht werden.</p>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" @click="cancel">Abbrechen</button>
        <button class="button is-danger" @click="confirm">Löschen</button>
      </footer>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "TrashBox",
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    trashObjectName: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isModalActive: false
    };
  },
  watch: {
    isActive(newValue) {
      this.isModalActive = newValue;
    },
    isModalActive(newValue) {
      if (!newValue) {
        this.cancel();
      }
    }
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    confirm() {
      this.$emit("confirm");
    }
  }
};
</script>
